import * as React from 'react'
import styled from 'styled-components'
import { Card } from 'components/styled/card'
import CloseIcon from 'svgs/close-v2.svg'
import CheckIcon from 'svgs/check.svg'

import { ShadowText } from 'components/styled/typography'

import { SCREEN } from 'styles/screens'
import { getTranslation } from '../../data/dictionary'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  min-height: 800px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1520px;
  margin-bottom: 60px;
  p {
    font-weight: 400;
    line-height: 1.8;
    margin-bottom: 60px;
    font-size: 27px;
    letter-spacing: -0.1px;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  > div {
    flex: 0 0 47%;
  }
  h6 {
    font-weight: 400;
    line-height: 1.8;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
    flex-direction: column;
  }
`

const StyledCard = styled(Card)`
  position: relative;
  padding: 40px 48px 18px 48px;
  flex: 1 0 65%;
  align-self: stretch;
  max-width: 1000px;
  margin-top: 20px;
  > h6 {
    font-weight: 500;
  }
  > ul li {
    display: flex;
    align-items: center;
    > div {
      margin-right: 1rem;
    }
  }
  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

class FitnessToJoin extends React.Component {
  render() {
    const { description, title, should, not } = this.props

    return (
      <div>
        <Container className={this.props.type}>
          <FixedContainer>
            <ShadowText highlight>
              <h3>{title}</h3>
            </ShadowText>
            <p className="fs-30px">{description}</p>
            <DescriptionContainer>
              <StyledCard style={{ backgroundColor: this.props.column1bg }}>
                <h5 className="mt-0 ml-46px">{getTranslation('you_should_have',"You should have:", true)}</h5>
                <ul className="list-unstyled mt-0">
                  {should.map((s, index) => {
                    return (
                      <li className="mt-5px" key={`should-${index}`}>
                        <div className="d-flex">
                          <CheckIcon />
                        </div>
                        {s}
                      </li>
                    )
                  })}
                </ul>
              </StyledCard>
              <StyledCard
                style={{ backgroundColor: `${this.props.column2bg}` }}
              >
                <h5 className="mt-0 ml-58px">{getTranslation('not_goot_fit_if',"Not good fit if:", true)}</h5>
                <ul className="list-unstyled mt-0">
                  {not.map((s, index) => {
                    return (
                      <li key={`not-${index}`}>
                        <div className="d-flex">
                          <CloseIcon />
                        </div>
                        <span>{s}</span>
                      </li>
                    )
                  })}
                </ul>
              </StyledCard>
            </DescriptionContainer>
          </FixedContainer>
        </Container>
      </div>
    )
  }
}

export default FitnessToJoin
