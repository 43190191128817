import * as React from 'react'
import styled from 'styled-components'
import { Card } from 'components/styled/card'

// COMPONENTS
import { SCREEN } from 'styles/screens'
import { config } from '../../config'
import { ShadowText } from 'components/styled/typography'
import LinkedIn from 'svgs/linkedin.svg'
import { getImageUrl } from '../../Utils'

const Container = styled.div`
  .social {
    height: 20px;

    svg {
      margin-left: 0.5rem;

      path {
        transition: all 0.3s;
        fill: var(--primary);
      }

      &:hover {
        path {
          fill: var(--secondary);
        }
      }
    }
  }
  p.title {
    background-color: var(--secondary);
    width: fit-content;
    padding: 6px 30px;
    border-radius: 100px;
    font-size: 30px;
    font-weight: 500;
  }
  p.title.dean {
    background-color: var(--primary);
    color: #fff !important;
  }
  .companyWrapper {
    width: 66px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;

    .company {
      object-fit: cover;
      max-width: 33px;
      max-height: 33px;
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 800px;
  padding: 100px 60px;
  .row {
    img {
      margin-inline: 0 auto;
      max-width: 400px;
    }
    &:first-child {
      border-top: none !important;
    }
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #221f1f;
    padding-bottom: 70px;
    padding-top: 70px;
  }
  .row:nth-child(even) {
    flex-direction: row-reverse;
    .col-4 {
      img {
        @media only screen and (min-width: ${SCREEN.MOBILE}) {
          margin-inline: auto 0;
        }
      }
      padding-inline: 30px 0;
    }
    .col-8 {
      padding-inline: 0 30px;
    }
  }
  .col-4 {
    display: flex;
    align-items: flex-start;
    padding-inline: 0 30px;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    // padding-left: 30px;
    // padding-right: 30px;
    img {
      object-fit: cover;
      width: 100% !important;
    }
  }
  .col-8 {
    padding-inline: 30px 0;
    flex: 0 0 66.66%;
    max-width: 66.66%;
    // padding-left: 30px;
    // padding-right: 30px;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    p.title {
      background-color: var(--secondary);
      width: fit-content;
      padding: 6px 12px;
      border-radius: 100px;
      font-size: 20px;
      font-weight: 500;
    }
    padding: 16px;
    .row {
      padding-bottom: 30px;
      padding-top: 30px;
    }
    .col-4 {
      padding: 0 !important;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-8 {
      padding: 0;
      margin-top: 20px;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 1520px;
  align-items: flex-start;
  margin-top: 40px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    max-width: 100%;
  }
`

const StyledCard = styled(Card)`
  position: relative;
  padding: 40px 48px 18px 48px;
  flex: 1 0 65%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px !important;
  margin-bottom: 60px;
  flex-direction: row;
  > h6 {
    font-weight: 500;
  }
  > img {
    flex: 0 0 25%;
    max-width: 200px;
    margin-right: 3rem;
  }
  h6 {
    font-size: 23px;
    font-weight: 300;
    line-height: 1.8;
    max-width: 1000px;
  }
  p {
    overflow-wrap: break-word;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    > img {
      flex: 0 0 33%;
      max-width: 200px;
      margin-right: 3rem;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex-direction: column;
    margin-top: 30px !important;
    margin-bottom: 30px;
    text-align: left;
    img {
      flex: 25%;
      min-height: 200px;
      margin-left: auto;
      margin-right: auto;
    }
    h4 {
      text-align: center;
      font-size: 26px;
      margin: 0;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    h6 {
      text-align: center;
      font-size: 18px;
    }
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  background-color: var(--secondary);
  padding: 100px;
  > div {
    padding: 86px;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 0 !important;
    margin-bottom: 0;
    p {
      font-size: 22px;
    }
    img {
      max-width: 180px;
      min-height: auto;
    }
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
    padding: 40px;
    flex-direction: column;
    > div {
      padding: 40px 48px 18px 48px;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    align-items: flex-start;
    padding: 16px;
  }
`

const SupportedSection = ({ lead, lead2, dean }) => (
  <Container className="mt-100px">
    <FixedContainer>
      <ShadowText highlight>
        <h3>Who will lead the program</h3>
      </ShadowText>
      <div>
        {lead ? (
          <div className="row">
            <div className="col-4">
              <img
                src={`${config.CDN_BASE_URL}/${lead.image}`}
                alt={lead.name}
                layout="fixed"
                width={350}
                placeholder="tracedSVG"
              />
            </div>

            <div className="col-8">
              <div className="d-flex align-items-center">
                <h4 className="mt-0 mb-0">{lead.name}</h4>

                <a href={lead.linkedin} target="_blank" className="social">
                  <LinkedIn />
                </a>
              </div>
              {/* DEAN NEED TO HAVE DEAN CLASS near the title class */}
              <p className="title lh-1">Tutor</p>
              {/* <h6 className="mt-0">{lead.title}</h6> */}
              {/* <p className="mt-0">{lead.description}</p> */}
              <p className="mb-0">{lead.description}</p>
              <div className="d-flex flex-wrap">
                {lead.companyLogos.map(logo => {
                  return (
                    <div className="companyWrapper">
                      <img
                        className="company"
                        src={getImageUrl(logo.image)}
                        alt=""
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {lead2 && lead2.name ? (
          <div className="row">
            <div className="col-4">
              <img
                src={`${config.CDN_BASE_URL}/${lead2.image}`}
                alt={lead2.name}
                layout="fixed"
                width={350}
                placeholder="tracedSVG"
              />
            </div>
            <div className="col-8">
              <div className="d-flex align-items-center">
                <h4 className="mt-0 mb-0">{lead2.name}</h4>

                <a href={lead2.linkedin} target="_blank" className="social">
                  <LinkedIn />
                </a>
              </div>
              <p className="title lh-1">Tutor</p>
              {/* <h6 className="mt-0">{lead.title}</h6> */}
              {/* <p className="mt-0">{lead.description}</p> */}
              <p className="mb-0">{lead2.description}</p>
              <div className="d-flex flex-wrap">
                {lead2.companyLogos.map(logo => {
                  return (
                    <div className="companyWrapper">
                      <img
                        className="company"
                        src={getImageUrl(logo.image)}
                        alt=""
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {dean && dean.name ? (
          <div className="row">
            <div className="col-4">
              <img
                src={`${config.CDN_BASE_URL}/${dean.image}`}
                alt={dean.name}
                layout="fixed"
                width={350}
                placeholder="tracedSVG"
              />
            </div>
            <div className="col-8">
              <div className="d-flex align-items-center">
                <h4 className="mt-0 mb-0">{dean.name}</h4>

                <a href={dean.linkedin} target="_blank" className="social">
                  <LinkedIn />
                </a>
              </div>
              {/* DEAN NEED TO HAVE DEAN CLASS near the title class */}
              <p className="title lh-1 dean">Dean</p>
              {/* <h6 className="mt-0">{lead.title}</h6> */}
              {/* <p className="mt-0">{lead.description}</p> */}
              <p className="mb-0">{dean.description}</p>
              <div className="d-flex flex-wrap">
                {dean.companyLogos.map(logo => {
                  return (
                    <div className="companyWrapper">
                      <img
                        className="company"
                        src={getImageUrl(logo.image)}
                        alt=""
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </FixedContainer>
  </Container>
)

export default SupportedSection
