import React from 'react'
import styled from 'styled-components'

// COMPONENTS
// ASSETS
import { SCREEN } from 'styles/screens'
// import { getTranslation } from '../../data/dictionary'
import Back from 'svgs/newDesigns/lifecycleBackpart.svg'
import ProductManager from 'svgs/newDesigns/productManager.svg'
import ProductDesigner from 'svgs/newDesigns/productDesigner.svg'
import MarketingSales from 'svgs/newDesigns/marketingSales.svg'
import SoftwareEngineer from 'svgs/newDesigns/softwareEngineer.svg'

const Container = styled.div`
  overflow: scroll !important;
  margin-left: auto;
  max-width: 100%;
  // margin-top: 100px;
  padding-bottom: 50px;
  margin-right: auto;

  ul {
    padding-left: 20px;
  }

  .img-wrapper {
    min-height: 650px;
    &.height-fixed {
      overflow-y: hidden;
      max-height: 350px;
      min-height: unset !important;
    }
    position: relative;

    margin-top: 70px;
  }

  .calendar-line {
    position: absolute;
    left: 0;
    &:hover {
      rect {
        fill: #ecfb70 !important;
      }

      rect[width='288'] {
        fill: white !important;
      }
      path[stroke-width='0.5'] {
        fill: #ecfb70 !important;
      }
    }
  }

  .calendar-line:nth-child(2) {
    top: 140px;
  }
  .calendar-line:nth-child(3) {
    top: 270px;
  }
  .calendar-line:nth-child(4) {
    top: 400px;
  }
  .calendar-line:nth-child(5) {
    top: 530px;
  }

  .calendar-bg {
    margin-left: 130px;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    min-height: 600px;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-top: 50px;
  }
`

const Row = styled.div`
  display: flex;
  // min-width: 2740px;
  height: 100%;
`

const Calendar = ({ scheduleType = null }) => {
  return (
    <Container>
      <Row>
        <div className={`img-wrapper ${scheduleType ? 'height-fixed' : ''}`}>
          <Back className="calendar-bg" />
          {(scheduleType === null || scheduleType === 'product-manager') && (
            <ProductManager className="product-manager calendar-line" />
          )}
          {(scheduleType === null || scheduleType === 'product-designer') && (
            <ProductDesigner className="product-designer calendar-line" />
          )}
          {(scheduleType === null || scheduleType === 'marketing-sales') && (
            <MarketingSales className="marketing-sales calendar-line" />
          )}
          {(scheduleType === null || scheduleType === 'software-engineer') && (
            <SoftwareEngineer className="software-engineer calendar-line" />
          )}
        </div>
      </Row>
    </Container>
  )
}

export default Calendar
