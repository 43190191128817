import * as React from 'react'
import styled from 'styled-components'
import { Card } from 'components/styled/card'
import { ShadowText } from 'components/styled/typography'

import { SCREEN } from 'styles/screens'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 800px;
  background-color: var(--secondary);
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 1520px;
  align-items: flex-start;
  margin-top: 40px;
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  background-color: var(--secondary);
  padding: 40px 100px 40px 100px;
  > div {
    flex: 0 0 31%;
  }

  h6 {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 80px;
      height: 16px;
      top: -40px;
      background-color: #eff885;
    }
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
    flex-direction: column;
    margin-top: 40px;
    padding: 40px;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin-top: 20px;
    padding: 16px;
  }
`

const StyledCard = styled(Card)`
  position: relative;
  padding: 40px 30px 18px 30px;
  flex: 1 0 65%;
  max-width: 1000px;
  align-self: stretch;
  h6 {
    font-size: 25px;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-top: 15px;
  }
`

class TwoColumns extends React.Component {
  render() {
    const { title, slides } = this.props.careerBoost

    return (
      <div>
        <Container className={this.props.type}>
          <FixedContainer>
            <ShadowText highlight>
              {/* <h3 className="px-100px"></h3> */}
              <div className="white-highlighted-title">
                <h3 className="ml-5">{title}</h3>
              </div>
            </ShadowText>
            <DescriptionContainer>
              {slides.map((slide, index) => {
                return (
                  <StyledCard
                    style={{ backgroundColor: this.props.column1bg }}
                    key={`career-boost-${index}`}
                  >
                    <h6 className="lh-1 mb-0">{slide.title}</h6>
                    <p>{slide.description}</p>
                  </StyledCard>
                )
              })}
            </DescriptionContainer>
          </FixedContainer>
        </Container>
      </div>
    )
  }
}

export default TwoColumns
