import * as React from 'react'
import styled from 'styled-components'
import { Card } from 'components/styled/card'

import { SCREEN } from 'styles/screens'

const Container = styled.div`
  display: flex;
  padding: 40px 100px 40px 100px;
  justify-content: center;
  min-height: 500px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1520px;
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  > div {
    flex: 0 0 47%;
  }
  h6 {
    font-weight: 400;
    line-height: 1.8;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
    flex-direction: column;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
  }
`

const StyledCard = styled(Card)`
  position: relative;
  padding: 0;
  div.card-header {
    background-color: #ecfb70;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 40px 100px 9px 88px;
  }
  div.card-body {
    padding: 20px 48px 9px 48px;
  }
  flex: 1 0 65%;
  max-width: 1000px;
  align-self: stretch;
  margin-bottom: 20px;
  > h6 {
    font-weight: 500;
  }
`

class TwoColumns extends React.Component {
  render() {
    const { cornerstones } = this.props

    return (
      <div className="mw-1520px mx-auto">
        {cornerstones.title && (
          <h4 className="px-100px py-40px" style={{ textAlign: 'left' }}>
            {cornerstones.title}
          </h4>
        )}
        <Container className={this.props.type}>
          <FixedContainer>
            <DescriptionContainer className="mb-100px">
              <StyledCard>
                <div className="card-header">
                  <h5 className="mt-0">{cornerstones.section1.title}</h5>
                </div>
                <div className="card-body">
                  <ul>
                    {cornerstones.section1.items.map((item, index) => {
                      return (
                        <li className="mt-5px" key={`section-1-item${index}`}>
                          {item}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </StyledCard>
              <StyledCard>
                <div className="card-header">
                  <h5 className="mt-0">{cornerstones.section2.title}</h5>
                </div>
                <div className="card-body">
                  <ul>
                    {cornerstones.section2.items.map((item, index) => {
                      return (
                        <li className="mt-5px" key={`section-2-item${index}`}>
                          {item}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </StyledCard>
            </DescriptionContainer>
          </FixedContainer>
        </Container>
      </div>
    )
  }
}

export default TwoColumns
