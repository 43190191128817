import * as React from 'react'
import styled from 'styled-components'

// COMPONENTS
import { ShadowText } from 'components/styled/typography'

import { SCREEN } from 'styles/screens'
import { config } from '../../config'
import RenderHtml from '../shared/renderHtml'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 50px 40px 100px;
  min-height: 800px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1520px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 0 1 60%;
  p {
    font-weight: 400;
    line-height: 1.8;
    font-size: 27px;
    letter-spacing: -0.1px;
    max-width: 711px;
  }
  p:first-child {
    margin-top: 80px;
  }
`

const ImageContainer = styled.div`
  flex: 0 1 40%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 100px;
  align-items: center;
  img {
    max-width: 100%;
    height: auto;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    // order: -1;
    padding-right: 0;
    margin-bottom: 60px;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin-bottom: 40px;
    > div:first-child {
      margin: 24px 12px;
      &:before {
        right: -10px;
        top: -10px;
      }
      &:after {
        bottom: -3px;
        left: -10px;
        border: 1px solid var(--primary);
      }
    }
  }
`

const WhyBuildUpSection = ({ method }) => (
  <Container>
    <FixedContainer>
      <DescriptionContainer>
        <ShadowText highlight>
          <h3>{method.title}</h3>
        </ShadowText>
        <p className="fs-30px mt-0">
          <RenderHtml html={method.description} />
        </p>
      </DescriptionContainer>
      <ImageContainer>
        <div className="m-0 mb-100px">
          <img
            src={`${config.CDN_BASE_URL}/${method.image}`}
            alt="BuildUp method"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </div>
      </ImageContainer>
    </FixedContainer>
  </Container>
)

export default WhyBuildUpSection
