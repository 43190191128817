import * as React from 'react'
import styled from 'styled-components'
import { SCREEN } from 'styles/screens'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  max-width: 1520px;
  flex-direction: column;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
  p {
    font-size: 21px;
  }
`

class OneColumn extends React.Component {
  render() {
    const imgSrc = this.props.imgSrc
    const imgAlt = this.props.imgAlt
    const title = this.props.title
    const align = this.props.align || 'center'

    return (
      <Container className="mx-auto" style={{ maxWidth: '680px' }}>
        <img
          src={imgSrc}
          alt={imgAlt}
          layout="fixed"
          width={272}
          height={272}
          placeholder="tracedSVG"
          className={`rounded-pill mx-auto mt-80px ${imgSrc ? '' : 'd-none'}`}
        />
        <div className="text-center">
          {title && <h4 style={{ textAlign: align }}>{title}</h4>}
          <p dangerouslySetInnerHTML={{ __html: this.props.description }}></p>
        </div>
      </Container>
    )
  }
}

export default OneColumn
