import * as React from 'react'
import styled from 'styled-components'

// COMPONENTS
import { ShadowText } from 'components/styled/typography'
import Calendar from 'components/home/calendar'
import { Button, SecondaryButton } from 'components/styled/button'
import { SCREEN } from 'styles/screens'
import { config } from '../../config'
import RenderHtml from '../shared/renderHtml'
import ArrowIcon from 'svgs/arrow_top_right.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  min-height: 800px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
  button {
    margin-top: 15px;
    > svg {
      margin-left: 12px;
    }

    &:hover {
      background-color: var(--secondary);
      box-shadow: unset;
    }
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1520px;
  &.mw-100 {
    max-width: 100%;
  }
  p {
    font-weight: 400;
    line-height: 1.8;
    font-size: 27px;
    letter-spacing: -0.1px;
    max-width: 711px;
    &.calendar-text {
      max-width: 1520px;
    }
  }
  p:first-child {
    margin-top: 80px;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 0 1 60%;
`

const ImageContainer = styled.div`
  flex: 0 1 40%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  > div:first-child {
    flex: 1 1 50%;
    position: relative;
    width: 100%;
    margin-bottom: 50px;
    &:before {
      content: '';
      position: absolute;
      right: -22px;
      top: -17px;
      height: 65%;
      width: 65%;
      background-color: var(--secondary);
      z-index: -1;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: -22px;
      height: 65%;
      width: 65%;
      border: 3px solid var(--primary);
      z-index: -1;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    order: -1;
    padding-right: 0;
    margin-bottom: 60px;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin-bottom: 40px;
    > div:first-child {
      margin: 24px 12px;
      &:before {
        right: -10px;
        top: -10px;
      }
      &:after {
        bottom: -3px;
        left: -10px;
        border: 1px solid var(--primary);
      }
    }
  }
`

const WhyBuildUpSection = ({ why, scheduleType }) => (
  <Container>
    <FixedContainer>
      <DescriptionContainer>
        <ShadowText highlight>
          <h3>{why.title}</h3>
        </ShadowText>
        <p className="fs-30px">
          <RenderHtml html={why.description} />{' '}
        </p>
        <Button
          onClick={() => {
            window.location.href = '/portfolio'
          }}
        >
          See products <ArrowIcon />
        </Button>
      </DescriptionContainer>

      <ImageContainer>
        <div className="m-0 mb-100px">
          <img
            src={`${config.CDN_BASE_URL}/${why.image}`}
            alt="Why BuildUp"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </div>
      </ImageContainer>
    </FixedContainer>
    <FixedContainer className="d-flex flex-column mw-100">
      <p className="mt-100px calendar-text">
        Here is a rough timeline showing all the tasks you’ll learn to
        accomplish during your BuildUp education. Besides solving these
        challenges, you’ll also develop a comprehensive understanding of other
        professions in relation to your own. Such experience will be very useful
        as you get promoted and begin working with more professionals from other
        disciplines.
        <br />
        <br />
        <strong>Explore the full program schedule:</strong>
      </p>
      <Calendar scheduleType={scheduleType} />
    </FixedContainer>
  </Container>
)

export default WhyBuildUpSection
