import * as React from 'react'
import styled from 'styled-components'

// ASSETS
import ArrowIcon from 'svgs/arrow-up.svg'
import { SCREEN } from 'styles/screens'
import RenderHtml from './renderHtml'

const Questions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const QuestionItem = styled.details`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 3px solid var(--primary);
  // padding: 48px 20px;
  cursor: pointer;
  transition: all 400ms;
  &:hover {
    background-color: var(--secondary);
  }
  // > summary {
  //   list-style: none;
  //   display: flex;
  //   align-items: center;
  //   font-size: 27px;
  //   position: relative;
  //   outline: none;
  //   flex-direction: row;
  //   column-gap: 20px;
  //   svg {
  //     position: absolute;
  //     right: 0;
  //     top: 0;
  //   }
  }
  // > summary::-webkit-details-marker {
  //   display: none;
  // }
  p, p span {
    font-size: 24px;
    margin-left: 0 !important;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    > summary {
      padding-right: 50px;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 24px 10px;
    &:hover {
      background-color: unset;
    }
    > summary {
      font-size: 21px;
    }
    p, p span {
      font-size: 16px;
    }
  }

`
const StyledSummary = styled.summary`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 150px;
  background-color: transparent;
  border: 0px;
  margin-left: 10px;
  margin-right: 10px;
 

  b {
    font-size: 27px;
    flex-grow: 1;
    margin-right: 10px;
  }

  svg {
  allign-self: center;
    // flex-shrink: 0;
  }
`;

const FAQ = ({ questions }) => (
  <Questions>
    {questions.map(({ question, answer }, idx) => (
      <QuestionItem key={idx} className="faq-item">
        <StyledSummary>
          <b>{question}</b>
          <ArrowIcon />
        </StyledSummary>
        <div className="ml-2">
          <RenderHtml tag={'div'} html={answer} />{' '}
        </div>
      </QuestionItem>
    ))}
  </Questions>
)

export default FAQ
