import * as React from 'react'
import styled from 'styled-components'

import { ShadowText } from 'components/styled/typography'

import { SCREEN } from 'styles/screens'

import CapIcon from 'svgs/reasons/cap-icon.svg'
import DigitalClock from 'svgs/reasons/digital-clock.svg'
import Hourglass from 'svgs/reasons/hourglass-icon.svg'
import Job from 'svgs/reasons/job-icon.svg'
import Ownership from 'svgs/reasons/ownership-icon.svg'
import Product from 'svgs/reasons/product-icon.svg'
import Salary from 'svgs/reasons/salary-icon.svg'
import SecondJob from 'svgs/reasons/second-job-icon.svg'

import { getTranslation } from '../../data/dictionary'

const Container = styled.div`
  a {
    color: #000;
    text-decoration: underline;
    font-size: 22px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 0 100px;
  min-height: 800px;
  // margin-bottom: 60px;
  .fs-32px {
    font-size: 32px;
  }
  .mr-16px {
    margin-right: 16px;
  }
  .mt-37px {
    margin-top: 37px;
  }
  .mt-21px {
    margin-top: 21px;
  }
  .fs-22px {
    font-size: 22px;
  }
  .w-80 {
    width: 80px;
  }
  .h-80 {
    height: 80px;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1520px;
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: wrap;
  > div {
    flex: 0 0 47%;
    margin-bottom: 78px;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
    flex-direction: column;
    > div {
      margin-bottom: 30px;
    }
  }
`

class Reasons extends React.Component {
  render() {
    return (
      <div>
        <Container>
          <FixedContainer>
            <ShadowText highlight>
              <h3>{getTranslation('Reasons_title', 'Reasons to Apply')}</h3>
            </ShadowText>
            <DescriptionContainer>
              <div>
                <div className="d-flex  align-items-center">
                  <div className="w-80 h-80 mr-16px">
                    <DigitalClock />
                  </div>

                  <h6 className="fs-32px mt-0 mb-0">
                    {getTranslation('evening_program', 'It’s evening program')}
                  </h6>
                </div>
                <p className="mt-37px fs-22px">
                  {getTranslation(
                    'evening_program_text',
                    'You don’t need to worry about time. If you have free hours in the evening after work, you can easily learn at BuildUp and be sure that your main job won’t suffer.	',
                  )}
                </p>
              </div>
              <div>
                <div className="d-flex  align-items-center">
                  <div className="w-80 h-80 mr-16px">
                    <Salary />
                  </div>

                  <h6 className="fs-32px mt-0 mb-0">
                    {getTranslation(
                      'top_job',
                      'I have a top job and a good salary already, so what?',
                    )}
                  </h6>
                </div>
                <p className="mt-21px fs-22px">
                  {getTranslation(
                    'top_job_text',
                    'Well, yes, if you are a VP of Engineering at Microsoft just ignore this. But if you want to be one in the next 10 years, please apply here.	',
                  )}
                </p>
              </div>
              <div>
                <div className="d-flex  align-items-center">
                  <div className="w-80 h-80 mr-16px">
                    <SecondJob />
                  </div>

                  <h6 className="fs-32px mt-0 mb-0">
                    {getTranslation(
                      'levi_job',
                      'Consumed by your “Levi” job every evening?',
                    )}
                  </h6>
                </div>
                <p className="mt-21px fs-22px">
                  {getTranslation(
                    'levi_job_text',
                    "You definitely don't want to work for a second job in the evenings your whole life, right? So why not learn and upgrade your salary to enjoy life.	",
                  )}
                </p>
              </div>
              {/* <div>
                <div className="d-flex align-items-center">
                  <div className="w-80 h-8 mr-16px0">
                    <Job />
                  </div>

                  <h6 className="fs-32px mt-0 mb-0">
                    {getTranslation(
                      'dream_to_work',
                      'Dream to work for ... Google?',
                    )}
                  </h6>
                </div>
                <p className="mt-21px fs-22px">
                  {getTranslation(
                    'dream_to_work_text',
                    "Designers who want to hone their skills so the world's leading tech companies can hire them. Check our community here.	",
                  )}
                </p>
              </div> */}
              <div>
                <div className="d-flex  align-items-center">
                  <div className="w-80 h-80 mr-16px">
                    <Ownership />
                  </div>

                  <h6 className="fs-32px mt-0 mb-0">
                    {getTranslation(
                      'internship_product',
                      'It is not an internship, You own the product',
                    )}
                  </h6>
                </div>
                <p className="mt-21px fs-22px">
                  {getTranslation(
                    'internship_product_text',
                    'This is not just another internship. You build a product with the team, and you own the product with your team.	',
                  )}
                </p>
              </div>
              {/* <div>
                <div className="d-flex  align-items-center">
                  <div className="w-80 h-80 mr-16px">
                    <Product />
                  </div>

                  <h6 className="fs-32px mt-0 mb-0">
                    {getTranslation(
                      'care_to_build',
                      'Care to build successful products?	',
                    )}
                  </h6>
                </div>
                <p className="mt-21px fs-22px">
                  {getTranslation(
                    'care_to_build_text',
                    'Developers who want to learn how to apply their skills to make the next big digital product.	',
                  )}
                </p>
              </div> */}
              <div>
                <div className="d-flex  align-items-center">
                  <div className="w-80 h-80 mr-16px">
                    <Hourglass />
                  </div>

                  <h6 className="fs-32px mt-0 mb-0">
                    {getTranslation(
                      'learning_seniors',
                      'I am already learning from my seniors but ...',
                    )}
                  </h6>
                </div>
                <p className="mt-21px fs-22px">
                  {getTranslation(
                    'learning_seniors_text',
                    'Yes, you do. And it might be enough if you want to become a professional in 3-5 years. So we are offering to do it in 4 months and with a whole new perspective.	',
                  )}
                </p>
              </div>
              <div>
                <div className="d-flex  align-items-center">
                  <div className="w-80 h-80 mr-16px">
                    <CapIcon />
                  </div>

                  <h6 className="fs-32px mt-0 mb-0">
                    {getTranslation(
                      'smaller_reasons',
                      'Oh and smaller reasons to consider',
                    )}
                  </h6>
                </div>
                <p className="mt-21px fs-22px">
                  {getTranslation(
                    'smaller_reasons_text',
                    'All tutors are top-level professionals. We teach mindset, so you learn critical thinking and complex problem-solving. You are a multi-disciplinary team to solving real-life problems. And eventually, check our community that will mentor you.',
                  )}
                </p>
              </div>
            </DescriptionContainer>
          </FixedContainer>
        </Container>
      </div>
    )
  }
}

export default Reasons
