import * as React from 'react'
import styled from 'styled-components'
import { Card } from 'components/styled/card'
import { ShadowText } from 'components/styled/typography'

import { SCREEN } from 'styles/screens'
import { config } from '../../config'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  min-height: 800px;
  margin-bottom: 60px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1520px;
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: wrap;
  > div {
    flex: 0 0 47%;
    margin-bottom: 20px;
  }
  h6 {
    font-weight: 400;
    line-height: 1.8;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
    flex-direction: column;
  }
`

const StyledCard = styled(Card)`
  position: relative;
  padding: 40px 30px 40px 30px;
  box-shadow: 0px 20px 30px rgba(14, 36, 58, 0.1)
  flex: 1 0 65%;
  max-width: 1000px;
  align-self: stretch;
  > h6 {
    font-weight: 500;
  }
  > img {
    display: flex;
    width: 80px;
    height: 70px;
    margin-right: 1rem;
    align-self: stretch;
  }
  > p {
    margin-bottom: 0;
    margin-top: 0;
  }
`

class Skills extends React.Component {
  render() {
    const title = this.props.title

    return (
      <div>
        <Container className={this.props.type}>
          <FixedContainer>
            <ShadowText highlight>
              <h3>{title}</h3>
            </ShadowText>
            <DescriptionContainer>
              {this.props.skills.map(skill => (
                <StyledCard key={skill.id} className="flex-row">
                  <img
                    width={90}
                    alt=""
                    height={80}
                    src={`${config.CDN_BASE_URL}/${skill.src}`}
                  />
                  <p>{skill.description}</p>
                </StyledCard>
              ))}
            </DescriptionContainer>
          </FixedContainer>
        </Container>
      </div>
    )
  }
}

export default Skills
