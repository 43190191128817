import * as React from 'react'
import styled from 'styled-components'

import OneColumn from 'components/program/oneColumn'

// ASSETS
import { SCREEN } from 'styles/screens'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  flex-direction: column;
  > div:last-child {
    max-width: 920px !important;
    margin-top: 100px;
    p {
      font-size: 27px;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
    margin-left: 10px;
    margin-right: 10px;
    > div:last-child {
      margin-top: 40px;
      padding: 0;
      p {
        text-align: left;
        font-size: 21px;
      }
    }
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 1200px;
  width: 100%;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 50vh;
  min-width: 100%;
  .embed-responsive {
    padding-top: 56.25% !important;
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    &:before {
      content: '';
      position: absolute;
      top: -15px;
      left: -20px;
      height: 80%;
      width: 50%;
      border: 3px solid var(--primary);
      z-index: -1;
    }
    &:after {
      content: '';
      position: absolute;
      right: -20px;
      bottom: -20px;
      height: 75%;
      width: 35%;
      background-color: var(--secondary);
      z-index: -1;
    }
    .embed-responsive-item {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    min-height: auto;
    width: 100%;
    &:before {
      top: -10px;
      left: -10px;
      border: 2px solid var(--primary);
    }
    &:after {
      right: -10px;
      bottom: -10px;
    }
  }
`

class HeroSection extends React.Component {
  render() {
    const { description, video } = this.props.message
    return (
      <Container>
        <FixedContainer>
          <ImageContainer>
            <div className="embed-responsive">
              <iframe
                width="100%"
                height="100%"
                src={video}
                title="Vimeo video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="embed-responsive-item"
              />
            </div>
          </ImageContainer>
        </FixedContainer>

        <OneColumn description={description} />
      </Container>
    )
  }
}

export default HeroSection
