import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'

import { Card } from 'components/styled/card'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Slider from 'react-slick'

import ArrowRightIcon from 'svgs/arrow-right.svg'

import { ShadowText } from 'components/styled/typography'

import OneColumn from 'components/program/oneColumn'

import { SCREEN } from 'styles/screens'
import { config } from '../../config'

const Container = styled.div`
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  min-height: 800px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1520px;
  flex-direction: column;
  margin-bottom: 100px;
  div.mx-auto {
    max-width: 100% !important;
    h4 {
      margin-top: 0;
    }
    p {
      text-align: left;
      font-size: 27px;
    }
    .text-cewnter p {
      text-align: center;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin-bottom: 40px;
    div.mx-auto {
      h4 {
        text-align: left;
      }
      p {
        font-size: 21px;
      }
    }
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  max-width: 95vw;
  padding: 10px 30px;
  h6 {
    font-weight: 400;
    line-height: 1.8;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
    flex-direction: column;
  }
`

const StyledCard = styled(Card)`
  position: relative;
  padding: 0;
  height: 100%;
  max-width: 1000px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
  > h6 {
    font-weight: 500;
  }
  .position-relative {
    position: relative;
    .badge {
      width: 90px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      margin-left: 20px;
      border-radius: 50px;
      margin-top: 20px;
      background-color: #ecfb70;
    }
  }

  > .card-body {
    padding: 40px 20px 60px 20px;
    display: flex;
    flex-direction: column;
    > h5 {
      font-weight: bold !important;
      font-size: 22px;
      margin-bottom: 0;
    }

    .text-field {
      transition: max-height 0.5s ease-in-out;
    }
    .text-field.hidden {
      overflow: hidden;
      max-height: 273px;
    }
    .text-field.active {
      max-height: 600px;
      overflow: auto;
    }
    .read-or-hide {
      position: absolute;
      display: flex;
      align-items: center;
      bottom: 10px;
      right: 20px;
      cursor: pointer;
      svg {
        transform: rotate(90deg);
        transition: all .5s ease-in-out;
      }
    }
    .show-less {
      animation: show-less-opacity 0.05s;
      svg {
        transform: rotate(-90deg);
      }
    }
    @keyframes show-less-opacity {
      0% {
        opacity: 0;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    .position-relative {
      .badge {
        position: absolute;
        bottom: 10px;
        right: 10px;
        margin: 0;
      }
    }
  }
`
const ReadMore = ({ children }) => {
  const text = children
  const [isReadMore, setIsReadMore] = useState(true)
  const [isActive, setActive] = useState(false)

  const toggleClass = () => {
    setActive(!isActive)
    setIsReadMore(!isReadMore)
  }

  return (
    <div className={`${isActive ? 'active' : 'hidden'} text-field`}>
      <p>
        {text}
        <span
          onClick={toggleClass}
          className={isActive ? 'read-or-hide show-less' : 'read-or-hide'}
        >
          {isReadMore ? 'Read more' : ' Show less'}
          <ArrowRightIcon className="ml-2" />
        </span>
      </p>
    </div>
  )
}

class TwoColumns extends React.Component {
  render() {
    const { title, description, topics } = this.props.learnings

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            dots: false,
            slidesToScroll: 1,
          },
        },
      ],
    }

    return (
      <div>
        <Container className={this.props.type}>
          <FixedContainer>
            <ShadowText highlight>
              <h3>What you will learn from the program</h3>
            </ShadowText>
            <OneColumn title={title} align={'left'} description={description} />
            <DescriptionContainer>
              <div className="w-100">
                <Slider {...settings}>
                  {topics.map(topic => (
                    <StyledCard
                      key={topic.id}
                      style={{ backgroundColor: this.props.column1bg }}
                    >
                      <div className="position-relative">
                        <img
                          src={`${config.CDN_BASE_URL}/${topic.src}`}
                          alt="profile-picture"
                          layout="fullWidth"
                          width="100%"
                          placeholder="tracedSVG"
                        />
                        <div className="badge">Topic {topic.topicNumber}</div>
                      </div>

                      <div className="card-body">
                        <h5 className="mt-0">{topic.title}</h5>
                        <ReadMore maxLines="{3}" buttonClassName="class-name">
                          {topic.text}
                        </ReadMore>
                      </div>
                    </StyledCard>
                  ))}
                </Slider>
              </div>
            </DescriptionContainer>
          </FixedContainer>
        </Container>
      </div>
    )
  }
}

export default TwoColumns
